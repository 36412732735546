<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="requisition-edit"
  >
    <div>
      <h2 class="mg-y-2 mg-b-4">แก้ไข</h2>
      <el-steps
        v-if="statusEdit"
        :active="stepNow"
        align-center
        class="custom-step"
        finish-status="success"
      >
        <el-step>
          <template slot="title">
            <p>Basic Information of Client</p>
            <p>(ข้อมูลบริษัท)</p>
          </template>
        </el-step>
        <el-step>
          <template slot="title">
            <p>Certificate Schema</p>
            <p>(ข้อมูลที่ขอการรับรอง)</p>
          </template>
        </el-step>
        <el-step>
          <template slot="title">
            <p>Additional Documents</p>
            <p>(เอกสารเพิ่มเติม)</p>
          </template>
        </el-step>
        <el-step>
          <template slot="title">
            <p>Check Information</p>
            <p>(ตรวจสอบข้อมูล)</p>
          </template>
        </el-step>
      </el-steps>
      <el-form
        label-position="top"
        :model="form"
        ref="ruleForm"
        :rules="rules"
        status-icon
        v-if="!loading"
      >
        <Step1
          v-if="stepNow + 1 == 1"
          :form="form"
          :address="addressAll"
          :companyAll="companyAll"
          :errorAddress="errorAddress"
          :errorCompany="errorCompany"
        />
        <Step2
          :type="'add'"
          v-else-if="stepNow + 1 == 2"
          :form="form"
          @addCondition="addCondition"
          @delCondition="delCondition"
          @checkError="checkboxChangeError"
        />
        <Step3 v-else-if="stepNow + 1 == 3" :form="form" />
        <Step4
          :type="'view'"
          :haveEdit="true"
          v-else-if="stepNow + 1 == 4"
          :form="form"
          @editStep="editStep"
          :rules="rules"
        />
      </el-form>
      <div class="font-error mg-t-2" v-if="checkError">กรุณาเลือก ประเภท</div>
      <div
        :gutter="15"
        class="is-flex ai-center js-between mg-t-4"
        v-if="statusEdit"
      >
        <div>
          <el-button
            round
            @click="backStep()"
            v-if="stepNow > 0 && stepNow != endStep - 1"
            >Cancel</el-button
          >
        </div>

        <el-button
          type="warning"
          class="button-radius font-14"
          @click="nextStep()"
          :loading="loadingNext"
          v-if="stepNow != endStep - 1"
          >Next</el-button
        >
        <el-button
          type="warning"
          class="button-radius font-14"
          @click="submitEdit()"
          :loading="loadingNext"
          v-else
          >Save</el-button
        >
      </div>
      <div v-else class="is-flex ai-center js-between mg-t-4">
        <el-button round @click="$router.push(`/certification-requisition`)"
          >Cancel</el-button
        >
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import Step1 from "@/components/requisition/add/Step1";
import Step2 from "@/components/requisition/add/Step2";
import Step3 from "@/components/requisition/add/Step3";
import Step4 from "@/components/requisition/add/Step4";

import { mapState } from "vuex";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      imgDoc: (state) => state.imgLogoDoc,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  mounted() {
    this.getCompanyAll();
    this.getAddressAll();
    this.getRequisitionById();
  },
  data() {
    var validateAddress = (rule, value, callback) => {
      if (value === "") {
        this.loadingNext = false;
        return callback(new Error("กรุณากรอก Address Of Client"));
      } else {
        if (this.oldNameAddress != value) {
          let debounce = null;
          let callbackData = this.checkRepeat(value, "address");
          clearTimeout(debounce);
          debounce = setTimeout(async () => {
            if ((await callbackData) != 1) {
              this.loadingNext = false;
              callback();
            } else {
              this.loadingNext = false;
              this.errorAddress = true;
              callback();
            }
          }, 1000);
        } else {
          callback();
        }
      }
    };
    var validateCompany = (rule, value, callback) => {
      if (value === "") {
        this.loadingNext = false;
        return callback(new Error("กรุณากรอก Company name"));
      } else {
        if (this.oldNameCompany != value) {
          let debounce = null;
          let callbackData = this.checkRepeat(value, "company");
          clearTimeout(debounce);
          debounce = setTimeout(async () => {
            if ((await callbackData) != 1) {
              this.loadingNext = false;
              this.errorCompany = false;
              callback();
            } else {
              this.loadingNext = false;
              this.errorCompany = true;
              callback();
            }
          }, 1000);
        } else {
          callback();
        }
      }
    };
    return {
      errorAddress: false,
      errorCompany: false,
      loading: true,
      loadingNext: false,
      stepNow: 3, // step + 1
      endStep: 4,
      statusEdit: false,
      form: {},
      checkError: false,
      rules: {
        companyName: [
          { required: true, validator: validateCompany, trigger: "blur" },
        ],
        address: [
          { required: true, validator: validateAddress, trigger: "blur" },
        ],
        contactPerson: [
          {
            required: true,
            message: "กรุณากรอก Contact person name",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "กรุณากรอก Phone number",
            trigger: "blur",
          },
        ],
        files: [
          {
            type: "array",
            required: true,
            message: "กรุณา Upload File",
            trigger: "change",
          },
        ],
      },
      addressAll: [],
      companyAll: [],
      oldNameAddress: "",
      oldNameCompany: "",
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {
    async getRequisitionById() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/requisition/` + this.$route.params.id)
        .then(async (res) => {
          if (res.data.success) {
            this.dialogDetail = true;
            this.form = res.data.obj;
            this.oldNameCompany = JSON.parse(
              JSON.stringify(res.data.obj.companyName)
            );
            this.oldNameAddress = JSON.parse(
              JSON.stringify(res.data.obj.address)
            );
            let filterFood = await res.data.obj.companyType
              .filter((row) => row.type == 1)
              .map((mapRow) => {
                return { ...mapRow, valCatFoodSafety: "" };
              });

            let filterISO = await res.data.obj.companyType
              .filter((row) => row.type == 2)
              .map((mapRow) => {
                return { ...mapRow, valCatISO: "" };
              });

            let setFileList = [];
            if (this.form.files.length > 0) {
              setFileList = this.form.files.reduce((result, row) => {
                var obj = {};
                if (row.raw == undefined && row.raw == null) {
                  let setUrl =
                    this.imageUrl +
                    "certification/" +
                    this.form._id +
                    "/" +
                    row;
                  let typeThis = row.split(".");
                  if (typeThis[typeThis.length - 1] == "pdf") {
                    setUrl = this.imgDoc.pdf;
                  } else if (
                    typeThis[typeThis.length - 1] == "docx" ||
                    typeThis[typeThis.length - 1] == "doc"
                  ) {
                    setUrl = this.imgDoc.docx;
                  } else if (
                    typeThis[typeThis.length - 1] == "xlsx" ||
                    typeThis[typeThis.length - 1] == "xls" ||
                    typeThis[typeThis.length - 1] == "csv"
                  ) {
                    setUrl = this.imgDoc.xlsx;
                  } else if (
                    typeThis[typeThis.length - 1] == "ppt" ||
                    typeThis[typeThis.length - 1] == "pptx"
                  ) {
                    setUrl = this.imgDoc.ppt;
                  } else if (typeThis[typeThis.length - 1] == "zip") {
                    setUrl = this.imgDoc.zip;
                  }

                  obj = {
                    name: row,
                    url: setUrl,
                  };
                } else {
                  obj = {
                    name: row.file.name,
                    url: URL.createObjectURL(row.file.raw),
                  };
                }
                result.push(obj);
                return result;
              }, []);
            }
            this.form = {
              ...res.data.obj,
              checkboxFood: filterFood.length > 0 ? true : false,
              checkboxISO: filterISO.length > 0 ? true : false,
              conditionsFood: await filterFood,
              conditionsISO: await filterISO,
              delFiles: [],
              delCompany: [],
              fileList: setFileList,
            };
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    backStep() {
      this.stepNow -= 1;
      this.form.fileList = [];
    },
    nextStep() {
      this.loadingNext = true;
      if (this.stepNow == 2) {
        this.form.fileList = [];
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (
          this.stepNow == 1 &&
          this.form.checkboxFood == false &&
          this.form.checkboxISO == false
        ) {
          this.checkError = true;
          this.loadingNext = false;
        } else if (valid) {
          if (this.stepNow != this.endStep) {
            this.stepNow += 1;
          }
          this.loadingNext = false;
        } else {
          this.loadingNext = false;
        }
      });
    },
    checkRepeat(value, type) {
      let result = null;
      if (type == "address") {
        result = this.addressAll.find(
          (row) => row.value == value && row._id != this.$route.params.id
        );
      } else if (type == "company") {
        result = this.companyAll.find(
          (row) => row.value == value && row._id != this.$route.params.id
        );
      }

      if (result != undefined) {
        result = 1;
      } else {
        result = 0;
      }
      return result;
    },
    getCompanyAll() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/company`)
        .then((res) => {
          if (res.data.success) {
            this.companyAll = res.data.obj;
            if (this.companyAll.length > 0) {
              this.companyAll = this.companyAll.reduce((result, row) => {
                let obj = {
                  label: row.value.replaceAll(" ", "&nbsp;"),
                  value: row.value,
                };
                result.push(obj);
                return result;
              }, []);
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {});
    },
    getAddressAll() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/address`)
        .then((res) => {
          if (res.data.success) {
            this.addressAll = res.data.obj;
            if (this.addressAll.length > 0) {
              this.addressAll = this.addressAll.reduce((result, row) => {
                let obj = {
                  _id: row._id,
                  label: row.value.replaceAll(" ", "&nbsp;"),
                  value: row.value,
                };
                result.push(obj);
                return result;
              }, []);
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {});
    },
    addCondition(type) {
      if (type == "foodSafety") {
        this.form.conditionsFood.push({
          company: "",
          type: 1,
          standard: "",
          other: "",
          accrecditBy: "",
          catNo: [],
          auditCycle: "",
          valCatFoodSafety: "",
        });
      } else if (type == "iso") {
        this.form.conditionsISO.push({
          company: "",
          type: 2,
          standard: "",
          other: "",
          accrecditBy: "",
          catNo: [],
          auditCycle: "",
          valCatISO: "",
        });
      }
    },
    delCondition(type, index) {
      if (type == "foodSafety") {
        if (this.form.conditionsFood[index]._id != undefined) {
          this.form.delCompany.push(this.form.conditionsFood[index]._id);
        }
        this.form.conditionsFood.splice(index, 1);
      } else if (type == "iso") {
        if (this.form.conditionsISO[index]._id != undefined) {
          this.form.delCompany.push(this.form.conditionsISO[index]._id);
        }
        this.form.conditionsISO.splice(index, 1);
      }
    },
    async submitEdit() {
      this.loadingNext = true;
      let companyType = [];

      if (this.form.conditionsFood.length > 0) {
        this.form.conditionsFood.forEach((row, index) => {
          let setArr = {
            _id: row._id,
            type: row.type,
            company: row.company,
            standard: row.standard,
            other: row.other,
            accrecditBy: row.accrecditBy,
            scoped: row.scoped,
            catNo: row.catNo,
            auditCycle: row.auditCycle,
          };

          companyType.push(setArr);
        });
      }
      if (this.form.conditionsISO.length > 0) {
        this.form.conditionsISO.forEach((row, index) => {
          let setArr = {
            _id: row._id,
            type: row.type,
            company: row.company,
            standard: row.standard,
            other: row.other,
            accrecditBy: row.accrecditBy,
            scoped: row.scoped,
            catNo: row.catNo,
            auditCycle: row.auditCycle,
          };

          companyType.push(setArr);
        });
      }

      let obj = {
        companyName: this.form.companyName,
        juristicId: this.form.juristicId,
        address: this.form.address,
        contactPerson: this.form.contactPerson,
        phone: this.form.phone,
        email: this.form.email,
        companyType: await companyType,
        delCompany: this.form.delCompany,
        note: this.form.note,
      };
      let setFile = null;
      if (this.form.files.length > 0) {
        setFile = this.form.files.reduce((result, row) => {
          if (row.file != undefined && row.file != null) {
            result.push(row);
          }
          return result;
        }, []);
      }

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.put(`certification/requisition/edit/` + this.form._id, obj)
        .then(async (res) => {
          if (res.data.success) {
            if (setFile.length > 0) {
              await this.uploadFiles(setFile);
            }
            if (this.form.delFiles.length > 0) {
              await this.deleteFiles();
            }
            this.$notify({
              title: "แก้ไขข้อมูลสำเร็จ!",
              type: "success",
              customClass: "success",
            });
            this.$router.push(`/certification-requisition`);
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingNext = false;
        });
    },
    editStep(step) {
      this.stepNow = step - 1;
      this.form.fileList = [];
      this.statusEdit = true;
    },

    checkboxChangeError(val, type) {
      this.checkError = val;

      if (this.form.checkboxFood == false) {
        if (this.form.conditionsFood.length > 0) {
          this.form.conditionsFood.forEach((condition) => {
            if (condition._id != undefined && condition._id != null) {
              this.form.delCompany.push(condition._id);
            }
          });
          this.form.conditionsFood = [];
        }
      } else if (
        this.form.checkboxFood == true &&
        type == "foodSafety" &&
        this.form.conditionsFood.length == 0
      ) {
        this.addCondition("foodSafety");
      }

      if (this.form.checkboxISO == false) {
        if (this.form.conditionsISO.length > 0) {
          this.form.conditionsISO.forEach((condition) => {
            if (condition._id != undefined && condition._id != null) {
              this.form.delCompany.push(condition._id);
            }
          });
          this.form.conditionsISO = [];
        }
      } else if (
        this.form.checkboxISO == true &&
        type == "iso" &&
        this.form.conditionsISO.length == 0
      ) {
        this.addCondition("iso");
      }
    },
    async uploadFiles(data) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };

      var formData = new FormData();

      formData.append("_csrf", this.setCsrf);
      formData.append("certificationId", this.form._id);
      data.forEach((e) => {
        formData.append("filesUpload", e.file.raw);
        formData.append("filesName", e.file.name);
      });

      await HTTP.post(`certification/files`, formData, config)
        .then(async (res) => {
          if (res.data.success) {
            return true;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
    async deleteFiles() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      await HTTP.post(`certification/delete/files`, {
        certificationId: this.form._id,
        delFiles: this.form.delFiles,
      })
        .then((res) => {
          if (res.data.success) {
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },
  },
};
</script>
